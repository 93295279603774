@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 56, 127, 154, 1; /* #387F9A*/
    --color-secondary: 74, 76, 90; /* #4a4c5a*/
    --color-bold-text: 36, 42, 54; /* #242A36*/

    --color-bg: 243, 244, 245; /* #f3f4f5*/
    --color-bg-side: 252, 251, 255; /* #fcfdff*/
    --color-bg-black: 0, 0, 0;
    --digital-category-bgc: #ade8f4;
    --fashion-category-bgc: #ffddd2;
    --beauty-category-bgc: #ddd92a;
    --sport-category-bgc: #ffd60a;
    --house-category-bgc: #ccd5ae;
    --toy-category-bgc: #cce3de;
    --stationery-category-bgc: #fbb13c;
    --disabled-btn-color: 198, 215, 221; /*"#C6D7DD"*/

    --color-text-base: 66, 71, 80; /* #424750*/
    --color-text-muted: 107, 114, 128; /*#6b7280*/
    --color-text-side: 255, 231, 231; /*#fff*/
    --text-color: 255, 255, 255; /* #fff  added for white color  */
    --pending-status-color: 189, 148, 43, 1; /*#BD942B*/
    --delivered-status-color: 80, 201, 23, 1; /*#50C917*/
    --font-family: iranyekan, "IRANSans", "Tahoma";
  }

  .dark {
    --color-bg: 15, 23, 42; /* #0f172a*/
    --color-bg-side: 30, 41, 59; /* #1e293b*/
    --digital-category-bgc: #0d4c76;
    --fashion-category-bgc: #892a19;
    --beauty-category-bgc: #6d305f;
    --sport-category-bgc: #650606;
    --house-category-bgc: #164c4b;
    --toy-category-bgc: #6d3d4b;
    --stationery-category-bgc: #6a4202;

    --color-text-base: 226, 232, 240; /*#e2e8f0*/
    --color-text-muted: 140, 154, 175; /*#8c9aaf*/
    --color-text-side: 255, 231, 231; /*#fff*/
  }

  html[dir="ltr"] {
    --font-family: "Poppins", "Roboto", "sans-serif";
  }
}

main::-webkit-scrollbar {
  display: none;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: rgb(var(--color-bg));
  color: rgb(var(--color-text-base));
}

ul {
  list-style: unset !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/woff/iranyekanwebbold.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("/fonts/ttf/iranyekanwebbold.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/woff/iranyekanwebthin.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("/fonts/ttf/iranyekanwebthin.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/woff/iranyekanweblight.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("/fonts/ttf/iranyekanweblight.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/woff/iranyekanwebregular.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("/fonts/ttf/iranyekanwebregular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/woff/iranyekanwebmedium.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("/fonts/ttf/iranyekanwebmedium.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/woff/iranyekanwebextrabold.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("/fonts/ttf/iranyekanwebextrabold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 850;
  src: url("/fonts/woff/iranyekanwebblack.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("/fonts/ttf/iranyekanwebblack.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/woff/iranyekanwebextrablack.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("/fonts/ttf/iranyekanwebextrablack.ttf") format("truetype");
  font-display: swap;
}

.slick-dots li button::before {
  font-size: 12px !important;
}
/* .image_section > span {
    width: 220px !important;
    height: 240px !important;
} */

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.45 !important;
}

.slick-prev:before,
.slick-next:before {
  opacity: 9 !important;
  content: "" !important;
}

@media (min-width: 1024px) {
  .slick-dots li button:before {
    font-size: 12px;
  }
  .slick-dots {
    padding-bottom: 40px !important;
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: rgb(var(--color-bg));
}

::-webkit-scrollbar-thumb {
  /* background-color: #a71b4a; */
  border-radius: 100vw;
}

/* input */

input,
select {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}

input:focus,
select:focus {
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
  color: #b9b9b9;
  font-size: 14px;
}
.chakra-accordion__icon {
  height: 24px !important;
  width: 24px !important;
  position: absolute;
  right: 15px;
  top: 15px;
}

.object-contain {
  object-fit: unset !important;
}

.app_header_b {
  /* margin-left: -20px; */
  /* margin-right: -20px; */
  padding: 0px 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1) !important;
}
.border_radius_all {
  border-radius: 12px !important;
}
.css-690el4[aria-checked="true"],
.css-690el4[data-checked] {
  background-color: #387f9a !important;
  border-color: #387f9a !important;
}
.css-690el4 {
  border: 1px solid !important;
  border-color: #387f9a !important;
}
.product_description_text p {
  font-size: 15px;
}
.product_description_text p:first-child {
  padding-bottom: 10px;
  padding-top: 10px;
}
.product_description_text ul li {
  padding-bottom: 5px;

  /* list-style-position: inside; */

  margin-left: 16px;
}
.font-bold {
  color: rgba(var(--color-primary));
}
.hideScroll::-webkit-scrollbar {
  display: none;
}
